.container {
  flex-direction: column;
}

.header {
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #8581ff;
  align-items: center;
  padding-left: 20px;
}

.text {
  font-size: 14px;
  color: white;
  text-align: left;
  margin-left: 20px;
}
