.container {
  flex-direction: column;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.inputContainer {
  flex-direction: row;
  justify-content: space-evenly;
}

.inputBox {
  width: 36px;
  height: 48px;
  margin-left: 4px;
  border-radius: 4px;
  border: 2px solid rgba(77, 77, 77, 0.5);
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
}

.inputBox:focus {
  border: 2px solid #8581ff;
  outline: none;
}

.title {
  font-size: 18px;
  text-align: center;
  color: #4d4d4d;
}

.pairingLabel {
  font-size: 14px;
  text-align: center;
  color: #4d4d4d;
  margin-top: 12px;
}
