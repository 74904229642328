.aboutModal {
  position: absolute;
  top: 0px;
  left: 8px;
  right: 8px;
  height: 148px;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 4px;
  padding-top: 12px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.aboutModal:focus {
  outline: none;
}

.aboutModalOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.75);
}

.aboutModalTitle {
  font-size: 14px;
  text-align: center;
  color: #8581ff;
  margin: 0px;
  margin-bottom: 12px;
}

.aboutText {
  font-size: 12px;
  text-align: center;
  color: #4d4d4d;
  margin: 0px;
}

.aboutCancelButton {
  background-color: rgb(195, 195, 195);
  border-color: transparent;
  border-radius: 4px;
  color: #4d4d4d;
  width: 80px;
  height: 24px;
  margin-top: 12px;
  font-size: 12px;
}
