.settingsContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-left: 20px;
}

.settingsContent {
  display: flex;
  align-self: center;
  margin-top: 8px;
}

.titleContainer {
  display: flex;
  height: 50px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.settingsTitle {
  font-size: 18px;
  text-align: center;
  color: #4d4d4d;
}

.subtitle {
  font-size: 18px;
  text-align: left;
  margin-left: 21px;
  color: #4d4d4d;
}

.labelContainer {
  display: flex;
  margin-left: 21px;
  margin-right: 21px;
  margin-top: 0;
  margin-bottom: 0px;
  background-color: #e4e7eb;
  padding: 10px;
  align-items: flex-start;
}

.label {
  font-size: 14px;
  color: #4d4d4d;
  margin: 0px;
}

.audioLevelContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 30px;
  height: 18px;
}

.micIcon {
  margin-right: 10px;
}

.audioSquareOff {
  width: 14px;
  height: 6px;
  border-radius: 4px;
  background-color: #e4e7eb;
  margin-right: 12px;
}

.audioSquareOn {
  width: 14px;
  height: 6px;
  border-radius: 4px;
  background-color: #6fcf97;
  margin-right: 12px;
}

.testButton {
  border-radius: 5px;
  border: solid 1px #8581ff;
  background-color: #8581ff;
  font-size: 14px;
  color: #fff;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
  max-width: 60px;
}

.controlsButton {
  border-radius: 5px;
  border: solid 1px #8581ff;
  background-color: #8581ff;
  font-size: 14px;
  color: #fff;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
  align-self: center;
}

.disconnectIconButton {
  position: absolute;
  left: 20px;
  background-color: transparent;
  border-color: transparent;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 18px;
  margin-top: 48px;
  margin-left: 16px;
  margin-right: 16px;
}

.cancelButton {
  left: 8px;
  background-color: rgb(195, 195, 195);
  border-color: transparent;
  border-radius: 4px;
  color: #4d4d4d;
  width: 160px;
  height: 48px;
  margin-right: 8px;
  font-size: 18px;
}

.disconnectButton {
  left: 8px;
  background-color: #d30000;
  border-color: transparent;
  border-radius: 4px;
  color: #fff;
  width: 160px;
  height: 48px;
  margin-left: 8px;
  font-size: 18px;
}

.modal {
  position: absolute;
  top: 0px;
  left: 8px;
  right: 8px;
  height: 148px;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 4px;
  padding-top: 48px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.modal:focus {
  outline: none;
}

.modalOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.75);
}

.modalTitle {
  font-size: 18px;
  text-align: center;
  color: #4d4d4d;
  margin: 0px;
}
