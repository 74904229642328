.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.circle {
  width: 48px;
  height: 48px;
  border: solid 3px #6fcf97;
  border-radius: 50%;
  position: absolute;
}

.check {
  position: absolute;
}

.title {
  font-size: 18px;
  text-align: center;
  color: #4d4d4d;
}

.successLabel {
  font-size: 14px;
  text-align: center;
  color: #4d4d4d;
  margin-top: 12px;
}

.button {
  border-radius: 5px;
  border: solid 1px #8581ff;
  background-color: #8581ff;
  font-size: 14px;
  color: #fff;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
}
