.controlsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.controlsTitle {
  font-size: 18px;
  text-align: center;
  color: #4d4d4d;
}

.controlsSubtitle {
  font-size: 18px;
  text-align: center;
  color: #4d4d4d;
}

.directionalContainer {
  display: flex;
  border: solid 1px rgba(77, 77, 77, 0.5);
  border-radius: 50%;
  height: 200px;
  width: 200px;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
}

.directionalButton {
  background-color: transparent;
  border-color: transparent;
  outline: none;
}

.leftDirectionalButton {
  background-color: transparent;
  border-color: transparent;
  border-radius: 150px 0 0 150px;
  border: solid 1px rgba(77, 77, 77, 0.5);
  border-right: 0px;
  height: 75px;
  width: 39px;
  outline: none;
}

.rightDirectionalButton {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 150px 150px 0; 
  border: solid 1px rgba(77, 77, 77, 0.5);
  border-left: 0px;
  height: 75px;
  width: 39px;
  outline: none;
}

.upIconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.downIconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centerIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.zoomContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
}

.zoomCenter {
  border: solid 1px rgba(77, 77, 77, 0.5);
  border-right: 0px;
  border-left: 0px;
  height: 73px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zoomControl {
  font-size: 36px;
  text-align: center;
  color: #8581ff;
  margin: 0px;
}

.backButton {
  position: fixed;
  left: 0;
  top: 8;
  border-color: transparent;
}
